import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { linkResolver } from '../utils/link_resolver'
import { PrismicRichText } from '@prismicio/react'

const TextImage = ({primary}) => {
  //console.log('primary: ', primary);
  return (
    <>
      <div className="w-full pt-24 bg-grey">
        <div className="w-11/12 mx-auto max-w-screen-xl">
          {primary.layout === 'image_left' ?
            <div className="flex-none sm:flex justify-between gap-12">
              <figure className="hidden sm:block w-full sm:w-6/12" data-sal-duration="750" data-sal="slide-right" data-sal-easing="ease">
                <GatsbyImage className="w-full h-full object-cover block" image={primary.image1.gatsbyImageData} alt={primary.image1.alt ?? ''} />
              </figure>
              <div className="w-full sm:w-6/12 sm:py-48">
                <div className="prose sm:prose-lg font-verdana text-black leading-normal">
                  <PrismicRichText field={primary.text1.richText} />
                </div>
                {primary.page_link && primary.button !== null && primary.page_link.link_type === 'Web' ? <div className="mt-12">
                <Link to={primary.page_link.url} target={primary.page_link.target ?? ''} className="font-verdana text-lg font-bold bg-primary px-6 py-2 text-white hover:text-white">{primary.button} <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                </div> : ''}
                {primary.page_link && primary.button !== null && primary.page_link.link_type === 'Media' ? <div className="mt-12">
                <Link to={primary.page_link.url} className="font-verdana text-lg font-bold bg-primary px-6 py-2 text-white hover:text-white">{primary.button} <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                </div> : ''}
                {primary.page_link && primary.button !== null && primary.page_link.link_type === 'Document' ? <div className="mt-12">
                <Link to={linkResolver(primary.page_link)} className="font-verdana text-lg font-bold bg-primary px-6 py-2 text-white hover:text-white">{primary.button} <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                </div> : ''}
              </div>
            </div>
          :
            <div className="flex-none sm:flex justify-between gap-12">
              <div className="w-full sm:w-6/12 sm:py-48">
                <div className="prose sm:prose-lg font-verdana text-black leading-normal">
                  <PrismicRichText field={primary.text1.richText} />
                </div>
                {primary.page_link && primary.button !== null && primary.page_link.link_type === 'Web' ? <div className="mt-12">
                <Link to={primary.page_link.url} target={primary.page_link.target ?? ''} className="font-verdana text-lg font-bold bg-primary px-6 py-2 text-white hover:text-white">{primary.button} <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                </div> : ''}
                {primary.page_link && primary.button !== null && primary.page_link.link_type === 'Media' ? <div className="mt-12">
                <Link to={primary.page_link.url} className="font-verdana text-lg font-bold bg-primary px-6 py-2 text-white hover:text-white">{primary.button} <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                </div> : ''}
                {primary.page_link && primary.button !== null && primary.page_link.link_type === 'Document' ? <div className="mt-12">
                <Link to={linkResolver(primary.page_link)} className="font-verdana text-lg font-bold bg-primary px-6 py-2 text-white hover:text-white">{primary.button} <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                </div> : ''}
              </div>
              <figure className="hidden sm:block w-full sm:w-6/12" data-sal-duration="750" data-sal="slide-left" data-sal-easing="ease">
                <GatsbyImage className="w-full h-full object-cover block" image={primary.image1.gatsbyImageData} alt={primary.image1.alt ?? ''} />
              </figure>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default TextImage;