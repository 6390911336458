import React from 'react'
import { graphql } from 'gatsby'
import { linkResolver } from '../utils/link_resolver'
import { GatsbyImage } from "gatsby-plugin-image"
import Nav from '../components/nav'
import Footer from '../components/footer'
import Seo from '../components/seo'
import TextImage from '../components/text_image'

const AboutPage = ({data}) => {
  const entry = data.prismicAboutPage
  if (!entry) return null
  return (
    <div>
      <Nav />
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <div className="w-full py-12 bg-grey">
        <div className="w-11/12 mx-auto max-w-screen-xl">
          <div className="flex-none sm:flex justify-between gap-24">
            <div className="w-full sm:w-8/12">
              <h1 className="font-verdana font-bold text-black text-3xl sm:text-4xl md:text-5xl leading-tight">
                {entry.data.heading}
              </h1>
            </div>
            <div className="hidden sm:block sm:w-4/12">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <GatsbyImage className="aspect-w-1 aspect-h-1" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
      </div>
      {entry.data.body.map((slice) => {
        if (slice.slice_type === 'text_image') {
          return (
            <div key={slice.id}>
              <TextImage primary={slice.primary} />
            </div>
          )
        }
      })}
      <Footer />
    </div>
	)
}

export default AboutPage;

export const query = graphql`
query {
  prismicAboutPage {
    data {
      meta_description
      page_title
      heading
      image {
        alt
        gatsbyImageData
      }
      body {
        ... on PrismicAboutPageDataBodyTextImage {
          id
          slice_type
          primary {
            layout
            text1 {
              richText
            }
            image1 {
              alt
              gatsbyImageData
            }
            button
            page_link {
              url
              uid
              type
              link_type
            }
          }
        }
      }
    }
  }
}
`